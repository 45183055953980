import { Manifestation, Title, UniformTitleMusic, Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { sift } from "radash";
import { SortableManifestation } from "../types";

export const havePartTitles = (title: Title | UniformTitleMusic) =>
  title.titleType !== "uniform_title_music" && (!!title.partTitle || !!title.partNumber);

export const haveSamePartTitlesSctructure = (leftTitle: Title, rightTitle: Title) =>
  havePartTitles(leftTitle) === havePartTitles(rightTitle);

export const getOriginalLanguageTitle = (work: Work, { expression }: Partial<SortableManifestation> = {}) =>
  !!work.title.titleLanguage &&
  !expression?.languages?.some(({ code }) => !!code && code === work.title.titleLanguage?.code)
    ? formatIsbdTitle(work, { skipSubtitle: true })
    : undefined;

export const getParallelTitles = (manifestation: Pick<Manifestation, "parallelTitles"> | undefined) =>
  sift(manifestation?.parallelTitles.map((title) => formatIsbdTitle({ ...manifestation, title })) ?? []);
