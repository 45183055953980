import fetch from "cross-fetch";
import { shake } from "radash";

export type ForrigeBokWork = {
  id: string;
  simplifiedPresentationMetadata: SimplifiedPresentationMetadata;
  publications: ForrigeBokPublication[];
  appealTerms: AppealTerm[];
  numberOfRegistrations: number;
};

interface SimplifiedPresentationMetadata {
  title: string;
  subtitle: string;
  authors: string[];
  coverImage: string;
  summary: string;
  originalYear: string;
  genre: string[];
  about: string[];
}

export interface AppealTerm {
  term: {
    id: string;
    label: string;
    factor: AppealFactor;
    facet: AppealFacet;
  };
  averageWeight: number;
}

export interface AppealFactor {
  id: string;
  label: string;
}

export interface AppealFacet {
  id: string;
  label: string;
}

export interface ForrigeBokPublication {
  id: string;
  isbn: string;
  title: string;
  subtitle: string;
  coverImage: string;
  datePublished: string;
  language: string;
  type: "AudioBook" | "Book" | "VideoGame" | "Movie";
}

interface ForrigeBokWorkResponse {
  works: ForrigeBokWork[];
  total: number;
}

interface ForrigeBokReadalikesResponse {
  readalikes: ForrigeBokWork[];
}

export interface VocabularyResponse {
  factors: VocabularyFactor[];
  facets: VocabularyFacet[];
  terms: VocabularyTerm[];
}

export interface VocabularyFactor {
  name: string;
  id: string;
}

export interface VocabularyFacet {
  name: string;
  id: string;
  factorId: string;
}

export interface VocabularyTerm {
  name: string;
  id: string;
  factorId: string;
  facetId: string;
  definition: string;
  synonyms: string[];
  scopeNote: string | null;
}

const forrigebokFetcher = <T>(path: string) =>
  fetch(`https://forrigebok.no/api/v2023-01-12${path}`, {
    headers: {
      "Client-Identifier": "Libry Content",
    },
  }).then((data) => data.json() as T);

interface GetWorksQueryParams {
  isbns?: string[];
  workIds?: string[];
  limit?: string;
  language?: string;
}

export const getRawForrigebokWorks = async (params: GetWorksQueryParams) => {
  const searchParams = new URLSearchParams(
    shake({
      limit: params.limit,
      language: getForrigebokLanguage(params.language),
      workId: params.workIds?.join(","),
      isbn: params.isbns?.join(","),
    })
  );

  return forrigebokFetcher<ForrigeBokWorkResponse>(`/works?${searchParams.toString()}`);
};

export type GetReadalikesQueryParams = {
  language?: string;
  limit?: number;
  isbn?: string;
  workId?: string;
  termIds?: string[];
};

/**
 * This function returns raw data from the ForrigeBok API
 * You probably want to use the getForrigebokReadalikesFilteredOnCatalogue in useForrigebokData which filters away readalikes that dont exist in the library catalogue and uses metadata from the librarysystem for correct cover images and metadata
 */
export const getRawForrigebokReadalikes = async (params: GetReadalikesQueryParams) => {
  const searchParams = new URLSearchParams(
    shake({
      limit: params.limit?.toString(),
      language: getForrigebokLanguage(params.language),
      workId: params.workId,
      isbn: params.isbn,
      terms: params.termIds?.join(","),
    })
  );

  return forrigebokFetcher<ForrigeBokReadalikesResponse>(`/readalikes?${searchParams.toString()}`);
};

/** Dette er et stort kall med mye data, kanskje se på måter for å redusere dataen her */
export const getForrigebokVocabulary = async (language?: string) =>
  forrigebokFetcher<VocabularyResponse>(`/vocabulary?language=${getForrigebokLanguage(language)}`);

const forrigebokLanguages = ["nb", "nn"] as const;
type ForrigebokLanguage = (typeof forrigebokLanguages)[number];
const isForrigebokLanguage = (language?: string): language is ForrigebokLanguage =>
  forrigebokLanguages.includes(language as ForrigebokLanguage) ? true : false;
const getForrigebokLanguage = (prefferedLanguage?: string): ForrigebokLanguage =>
  isForrigebokLanguage(prefferedLanguage) ? prefferedLanguage : "nb";
