import { CordataWemTriple } from "@biblioteksentralen/bmdb-search-client";
import { formatIsbdTitle } from "@biblioteksentralen/cordata";

export const getWorkData = (workTriple: CordataWemTriple) => {
  const { work, manifestation, expression } = workTriple;

  return {
    representativeManifestation: manifestation,
    imageUrl: chooseCoverImageQuality(manifestation.coverImage, "thumbnail"),
    mainContributor: getMainContributor(workTriple),
    year: (work.workYear ?? manifestation.publicationYear)?.toString(),
    formattedTitle: formatIsbdTitle(manifestation.title ? manifestation : work),
    isbn: manifestation.isbn[0],
    language: expression.languages[0],
    genres: work.genres,
    subjects: work.subjects,
    summary: work.summary,
  };
};

const qualities = ["original", "thumbnail", "blur"] as const;
type CoverImageQuality = (typeof qualities)[number];
const chooseCoverImageQuality = (url: string | undefined, quality: CoverImageQuality): string | undefined =>
  url?.replace(new RegExp(`\\/(?:${qualities.join("|")})\\.jpg$`), `/${quality}.jpg`);

const getMainContributor = ({ work, expression, manifestation }: CordataWemTriple) =>
  [...work.contributors, ...expression.contributors, ...manifestation.contributors].find(
    ({ isMainContributor }) => isMainContributor
  );
