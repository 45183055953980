import { ForrigeBokWork } from "./api";
import useSWR from "swr";

export const useForrigebokStatus = (isbn?: string) =>
  useSWR<{ total?: number; works?: ForrigeBokWork[] }>(
    isbn ? `https://forrigebok.no/api/v2022-01-12/works?isbn=${isbn}` : null,
    (url: string) =>
      fetch(url, {
        headers: {
          "Client-Identifier": "Libry Content",
        },
      }).then((response) => response.json())
  );
