import { Work } from "@biblioteksentralen/cordata";
import { getErrorMessage } from "@libry-content/common";
import { filterWorkData } from "../cordata/works";
import { defaultSizes } from "./constants";
import { SiteSearchConfig } from "./searchConfig";
import { bmdbFetcher } from "@libry-content/integrations";
import { PlasFacet, SearchWorksParams } from "@biblioteksentralen/bmdb-search-client";

export type WorkSearchResult = Work & { representativeManifestationId?: string };

export const searchWorks = async (
  siteSearchConfig: SiteSearchConfig,
  searchWorksParams: SearchWorksParams
): Promise<{
  works: WorkSearchResult[];
  facets?: PlasFacet[];
  endOfResults: boolean;
}> => {
  try {
    const { isSearchIntegrationEnabled, libraryCatalogueId } = siteSearchConfig;
    if (!isSearchIntegrationEnabled || !libraryCatalogueId) {
      throw new Error("Site settings: Search integration is not enabled for this site");
    }

    const { size = defaultSizes.works } = searchWorksParams;
    const responseData = await bmdbFetcher({
      operation: "searchWorks",
      params: {
        size,
        ...searchWorksParams,
      },
      siteSearchConfig,
    });

    if (!responseData) {
      throw new Error(
        `Unexpected works search response with ${
          searchWorksParams.query ? `search query "${searchWorksParams.query}` : "no search query"
        }": response = ${JSON.stringify(responseData)}`
      );
    }

    const works = responseData?.results
      .map(
        (result) =>
          ({ ...result.work, representativeManifestationId: result.representativeManifestationId } as WorkSearchResult)
      )
      .map(filterWorkData({ libraryCatalogueId }));
    const { facets, endOfResults } = responseData;

    return { works, facets, endOfResults };
  } catch (err) {
    console.error(`Could not search works: ${getErrorMessage(err)}`);
    return { works: [], facets: [], endOfResults: true };
  }
};

export const getWorks = async (
  siteSearchConfig: SiteSearchConfig,
  workIds: string[],
  useGlobalCatalogue?: boolean
): Promise<Work[]> => {
  try {
    if (!siteSearchConfig.isSearchIntegrationEnabled) {
      throw new Error("Site settings: Search integration is not enabled for this site");
    }

    if (workIds.length === 0) return [];

    const responseData = await bmdbFetcher({
      operation: "getWorks",
      params: { work_ids: workIds },
      siteSearchConfig,
      useGlobalCatalogue,
    });

    if (!responseData?.works) throw new Error(`Could not fetch works of IDs: ${workIds.join(", ")}`);

    return Object.values(responseData.works);
  } catch (err) {
    console.error(getErrorMessage(err));
    return [];
  }
};

/**
 * Filter out:
 * - Ebook-manifestations and expressions (temporary solution; can lead to empty works in the frontend)
 */
export const getFilteredWork = async (
  workId: string,
  searchConfig: SiteSearchConfig,
  useGlobalCatalogue?: boolean
): Promise<Work | undefined> => {
  const unfilteredWorks = await getWorks(searchConfig, [workId], useGlobalCatalogue);
  const work =
    unfilteredWorks && unfilteredWorks[0]
      ? filterWorkData({ libraryCatalogueId: searchConfig.libraryCatalogueId ?? "" })(unfilteredWorks[0])
      : undefined;

  if (!work) {
    console.warn(`Could not find work ${workId}`);
    return undefined;
  }

  return work;
};
