import { Work, isMovingImageWork } from "@biblioteksentralen/cordata";
import { LocalizedField } from "@libry-content/localization";

const workTypeMap = {
  språkbasertVerk: "https://schema.nb.no/Bibliographic/Values/V1000",
  filmVerk: "https://schema.nb.no/Bibliographic/Values/V1002",
  interaktivtVerk: "https://schema.nb.no/Bibliographic/Values/V1005",
};

const getDefiniteNoun = (work: Work | undefined) => {
  switch (
    work?.workTypes[0]?.uri //TODO: Musikkverk
  ) {
    case workTypeMap.filmVerk:
      return { nb: "filmen", nn: "filmen" };
    case workTypeMap.interaktivtVerk:
      return { nb: "spillet", nn: "spelet" };
    default:
      return { nb: "boka", nn: "boka" };
  }
};

export const getWorkTypeDefiniteNoun = (work: Work | undefined): LocalizedField<string> => getDefiniteNoun(work);

export const isMovie = (work: Pick<Work, "workTypes">) => isMovingImageWork(work);
