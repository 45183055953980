import { isJestTest, isNonNil, retry, RetryConfig, withoutUndefinedValues } from "@libry-content/common";
import fetch from "cross-fetch";
import xml2js from "xml2js";
import { MarcRecord, marcRecordFromXml2JsRecord, RawMarcRecord } from "./MarcRecord";

export interface SearchResponse {
  errors?: Diagnostic[];
  numberOfRecords?: number;
  records: MarcRecord[];
  source?: string;
}

export interface Diagnostic {
  message?: string;
  details?: string;
}

interface RawDiagnostic {
  message?: string[];
  details?: string[];
}

interface RawDiagnostics {
  diagnostic?: RawDiagnostic[];
}

interface RawRecordData {
  record?: RawMarcRecord[];
}

interface RawSruRecord {
  recordData?: RawRecordData[];
}

interface RawRecords {
  record?: RawSruRecord[];
}

interface RawSearchRetrieveResponse {
  diagnostics?: RawDiagnostics[];
  records?: (RawRecords | string)[];
  numberOfRecords?: string[];
}

export type FetchApi = typeof fetch;

interface SruClientOptions {
  defaultParams?: Record<string, string | undefined>;
  defaultRecordSchema?: string;
  defaultVersion?: string;
  fetchApi?: FetchApi;
  retryOptions?: RetryConfig;
}

interface SearchParams {
  sortKeys?: string;
  recordSchema?: string;
  version?: string;
  startRecord?: number;
  maximumRecords?: number;
}

const defaultRetryOptions: RetryConfig = {
  // Timeout is calculated as: Math.min(random * minTimeout * Math.pow(factor, attempt), maxTimeout)
  // Ref: https://github.com/tim-kos/node-retry#retrytimeoutsoptions
  retries: 1,
  minTimeout: 2000,
  onFailedAttempt: (error) =>
    console.warn(
      `Attempt ${error.attemptNumber} failed. There are ${error.retriesLeft} retries left. Error: ${error.toString()}`
    ),
};

export class SruClientFetchError extends Error {
  constructor(message: string) {
    super(`SruClientFetchError: ${message}`);
    this.name = "SruClientFetchError";

    // Workaround for https://github.com/microsoft/TypeScript/issues/13965
    // needed as long as we have a tsconfig.json compilation target of ES5.
    // See: https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, SruClientFetchError.prototype);
  }
}

export const createSruClient = (
  url: string,
  {
    defaultParams = {},
    defaultRecordSchema = "marc21",
    defaultVersion = "2.0",
    fetchApi = fetch,
    retryOptions = defaultRetryOptions,
  }: SruClientOptions = {}
) => {
  return {
    generateUrl,
    request,
    explain,
    search,
  };

  function generateUrl(params: Record<string, string | undefined>): string {
    const searchParams = new URLSearchParams(withoutUndefinedValues(params));

    return `${url}?${searchParams.toString()}`;
  }

  async function request<T>(operation: string, params = {}): Promise<{ url: string; body: T }> {
    return retry(async () => {
      const url = generateUrl({ operation, ...defaultParams, ...params });
      const response = await fetchApi(url);
      const body = await response.text();
      if (!response.ok) {
        throw new SruClientFetchError(
          `Got ${response.status} response from library system when requesting ${url}: ${body}`
        );
      }
      try {
        return {
          url,
          body: await new xml2js.Parser({
            explicitRoot: false,
            tagNameProcessors: [xml2js.processors.stripPrefix],
          }).parseStringPromise(body),
        };
      } catch (err) {
        throw new SruClientFetchError(`Got invalid XML response from library system when requesting ${url}`);
      }
    }, retryOptions);
  }

  async function explain() {
    const response = await request("explain");
    return response;
  }

  async function search(
    query: string | undefined,
    {
      sortKeys = undefined,
      recordSchema = defaultRecordSchema,
      version = defaultVersion,
      startRecord = 1,
      maximumRecords = 20,
    }: SearchParams = {}
  ): Promise<SearchResponse> {
    const response = await request<RawSearchRetrieveResponse>("searchRetrieve", {
      query,
      recordSchema,
      version,
      startRecord,
      maximumRecords,
      sortKeys,
    });
    const { diagnostics, numberOfRecords, records } = response.body || {};

    if (!query) {
      return {
        errors: [{ message: "Empty query" }],
        records: [],
      };
    }

    const errors: Diagnostic[] =
      diagnostics?.[0]?.diagnostic?.map((diagnostic) => ({
        message: diagnostic?.message?.[0],
        details: diagnostic?.details?.[0],
      })) || [];

    const rawRecords = records?.filter((record) => typeof record === "object") as RawRecords[];
    const marcRecords: MarcRecord[] =
      rawRecords?.[0]?.record
        ?.map((record) => {
          const rawMarcRecord = record.recordData?.[0]?.record?.[0];
          return rawMarcRecord ? marcRecordFromXml2JsRecord(rawMarcRecord) : undefined;
        })
        .filter(isNonNil) || [];

    !isJestTest &&
      errors.forEach((error: Diagnostic) => {
        console.warn(`Received SRU searchRetrieve diagnostic message: ${error.message} ${error.details}`);
      });

    return {
      source: response.url,
      errors,
      numberOfRecords:
        Array.isArray(numberOfRecords) && numberOfRecords?.[0] ? parseInt(numberOfRecords?.[0]) : undefined,
      records: marcRecords,
    };
  }
};

export type SruClientFactory = typeof createSruClient;
export type SruClient = ReturnType<SruClientFactory>;
