import { Manifestation, Work, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { sortAlphabetical } from "@biblioteksentralen/utils";
import { FrontendLocale } from "@libry-content/localization";
import { getAvailableWorkLanguages, getLanguagesSignature, toCordataLanguageCode } from "./languages";
import {
  WorkToBeRepresented,
  getFormattedRepresentativeManifestationTitle,
  getRepresentativeManifestation,
} from "./manifestations";
import { getParallelTitles } from "./titles";

export const isWork = (item: unknown): item is Work =>
  !!item && typeof item === "object" && (item as Work)?.type === "Work";

const isNotEbook = ({ documentType }: Pick<Manifestation, "documentType">) =>
  documentType?.format?.toLowerCase().replace("-", "") !== "ebook";

export const filterWorkData =
  ({ libraryCatalogueId }: { libraryCatalogueId: string }) =>
  (work: Work): Work => {
    if (!libraryCatalogueId) return work;

    const expressions = work.expressions
      ?.map(({ manifestations = [], aggregateManifestations = [], ...expression }) => ({
        ...expression,
        manifestations: manifestations.filter(isNotEbook),
        aggregateManifestations: aggregateManifestations.filter(isNotEbook),
      }))
      .filter(
        ({ manifestations = [], aggregateManifestations = [] }) =>
          manifestations.length > 0 || aggregateManifestations.length > 0
      );

    return { ...work, expressions };
  };

export const getWorkUrlLanguage = (work: WorkToBeRepresented, lang: FrontendLocale) => {
  if (work.representativeLanguages) return work.representativeLanguages;

  const siteLanguageCode = toCordataLanguageCode(lang);
  const haveExpressionWithSiteLanguage = (work.expressions ?? []).some(
    ({ languages = [] }) => languages.length === 1 && languages[0]?.code === siteLanguageCode
  );

  if (haveExpressionWithSiteLanguage) return siteLanguageCode;

  return getLanguagesSignature(getAvailableWorkLanguages(work)[0] ?? []);
};

export type WorkWithRepresentativeManifestationTitle = Work & {
  formattedTitle: string | undefined;
  parallelTitles: string[];
};

/**
 * Note that this function extracts a different representative manifestation for each associated work; we can't use
 * the representative manifestation for the work (containing the aggregate manifestation) of the current view.
 */
export const getWorksSortedOnRepresentativeManifestationTitle = (
  associatedWorks: Work[],
  lang: FrontendLocale
): WorkWithRepresentativeManifestationTitle[] => {
  return sortAlphabetical(
    associatedWorks.map((associatedWork) => {
      const representativeLanguages = getWorkUrlLanguage(associatedWork, lang);
      const representativeManifestation = getRepresentativeManifestation({
        ...associatedWork,
        representativeLanguages,
      });
      const formattedTitle =
        getFormattedRepresentativeManifestationTitle(associatedWork, representativeManifestation) ??
        formatIsbdTitle(associatedWork);
      const parallelTitles = getParallelTitles(representativeManifestation);
      return { ...associatedWork, formattedTitle, parallelTitles };
    }),
    ({ formattedTitle }) => formattedTitle ?? ""
  );
};

export const isInteractiveWork = (work: Work): boolean =>
  work.workTypes.some((workType) => workType.uri === "https://schema.nb.no/Bibliographic/Values/V1005");
