import { LibrarySystem, Site } from "@libry-content/types";

type SearchIntegrationEnvironment = NonNullable<Site["librarySystem"]>["searchIntegration"];

export type SiteSearchConfig =
  | {
      isSearchIntegrationEnabled: true;
      searchIntegrationEnvironment: SearchIntegrationEnvironment;
      searchApiHost: string;
      searchApiUrl: string;
      libraryCatalogueId: string;
    }
  | {
      isSearchIntegrationEnabled: false;
      searchIntegrationEnvironment: null;
      searchApiHost: null;
      searchApiUrl: null;
      libraryCatalogueId: null;
    };

const isEnabled = (searchEnv: SearchIntegrationEnvironment) => searchEnv === "prod" || searchEnv === "staging";

export const getSearchApiHost = (searchEnv: SearchIntegrationEnvironment) =>
  searchEnv === "prod" ? "https://search.data.bs.no" : "https://search.data-staging.bs.no";

const getSearchApiUrl = (searchEnv: SearchIntegrationEnvironment, librarySystem: LibrarySystem) =>
  searchEnv === "prod"
    ? `https://search.data.bs.no/cordata/${librarySystem.database}/api`
    : `https://search.data-staging.bs.no/cordata/${librarySystem.database}/api`;

export const getSearchConfig = ({ librarySystem }: Pick<Site, "librarySystem"> = {}): SiteSearchConfig =>
  librarySystem && isEnabled(librarySystem.searchIntegration) && librarySystem?.database
    ? {
        isSearchIntegrationEnabled: true,
        searchIntegrationEnvironment: librarySystem?.searchIntegration,

        // Vi utleder `libraryCatalogueId` fra `bibliofilInstance` hvis nettstedet er knytta til en underinstans av en
        // delt Bibliofil-database.  Vi har egentlig ingen garanti for at bibliofilInstance er unik på tvers av
        // databaser, men det er få databaser som bruker underinstanser (per juni 2024 er det kun Vågan), så i praksis
        // går det bra. Skulle det dukke opp kollisjoner kan vi gå over til å la `libraryCatalogueId` være et felt i
        // Sanity som fylles inn manuelt.
        libraryCatalogueId: librarySystem.bibliofilInstance ?? librarySystem.database,

        searchApiHost: getSearchApiHost(librarySystem.searchIntegration),
        searchApiUrl: getSearchApiUrl(librarySystem.searchIntegration, librarySystem),
      }
    : {
        isSearchIntegrationEnabled: false,
        searchIntegrationEnvironment: null,
        searchApiHost: null,
        searchApiUrl: null,
        libraryCatalogueId: null,
      };
