import { Work } from "@biblioteksentralen/cordata";
import { isNonNil } from "@libry-content/common/src/typeguards";
import { ForrigeBokWork, getRawForrigebokReadalikes } from "./api";
import { Site } from "@libry-content/types";
import { sift } from "radash";
import {
  SiteSearchConfig,
  getSearchConfig,
} from "../../../../apps/frontend/src/searchAndCollection/searchApi/searchConfig";
import { getWorks } from "../../../../apps/frontend/src/searchAndCollection/searchApi/searchWorks";
import { getLibrarySystemClient } from "../librarySystem";

/**
 * A readalike that has been converted to bmdb-format if we have a search integration, or forrigebok-format if we don't. By using bmdb-format we can show cover images and metadata from the library system instead of generic metadata from Forrigebok
 */
export type ConvertedReadalike = (Work & { format: "bmdb" }) | (ForrigeBokWorkWithUrl & { format: "forrigebok" });

export type ForrigeBokWorkWithUrl = ForrigeBokWork & { integratedWorkOrLibrarySystemUrl: string | undefined };

export const getForrigebokReadalikesFilteredOnCatalogue = async (config: {
  site: Site;
  workId?: string;
  isbn?: string;
  termIds?: string[];
  limit: number;
}): Promise<ConvertedReadalike[]> => {
  const { workId, isbn, termIds, limit } = config;

  const rawdataLimit = Math.min(limit + 4, 20); // Fetch 4 extra to account for missing entries, maxing at 20 which is forrigeboks hard limit
  const finalLimit = limit;

  const { readalikes } = await getRawForrigebokReadalikes({ workId, isbn, termIds, limit: rawdataLimit });

  if (!readalikes?.length || !config.site) return [];

  return filterAndTransformForrigebokWorks(readalikes, config.site, finalLimit);
};

const filterAndTransformForrigebokWorks = async (
  readalikes: ForrigeBokWork[],
  site: Site,
  limit: number
): Promise<ConvertedReadalike[]> => {
  const searchConfig = getSearchConfig(site);

  if (searchConfig?.isSearchIntegrationEnabled) {
    const bmdbReadalikes = transformForrigebokWorksToBMDBWorkAndRemoveMissingEntries(readalikes, searchConfig);
    return (await bmdbReadalikes).slice(0, limit).map((work) => ({ format: "bmdb", ...work }));
  }

  const forrigebokReadalikesWithUrl = transformLibraryCataloguePublicationsFromForrigebokWorks(readalikes, site);
  return (await forrigebokReadalikesWithUrl).slice(0, limit).map((work) => ({ format: "forrigebok", ...work }));
};

const transformForrigebokWorksToBMDBWorkAndRemoveMissingEntries = async (
  forrigeBokWorks: ForrigeBokWork[],
  siteSearchConfig: SiteSearchConfig
) => {
  const forrigeBokWorkIds = forrigeBokWorks.map(({ id }) => id);
  const bmdbWorks = await getWorks(siteSearchConfig, forrigeBokWorkIds);

  return sift(
    forrigeBokWorks.map((forrigebokWork) => bmdbWorks.find((bmdbWork) => bmdbWork.id === forrigebokWork.id)) // Må mappe fra forrigebokworks for å beholde riktig rekkefølge
  );
};

const transformLibraryCataloguePublicationsFromForrigebokWorks = async (
  forrigeBokWorks: ForrigeBokWork[],
  site: Site
): Promise<ForrigeBokWorkWithUrl[]> => {
  const existsInLibrarySystemWorks = await Promise.all(
    forrigeBokWorks.map(async (work) => await getLibraryCataloguePublicationFromForrigebokWork(work, site))
  );

  return sift(existsInLibrarySystemWorks);
};

const getLibraryCataloguePublicationFromForrigebokWork = async (
  work: ForrigeBokWork,
  site: Site
): Promise<ForrigeBokWorkWithUrl | undefined> => {
  const isbns = work.publications.map((publication) => publication.isbn).filter(isNonNil);

  const librarySystemClient = getLibrarySystemClient(site.librarySystem);

  const url = await librarySystemClient?.getLinkToRecordFromIsbn(isbns);

  const existsInLibrarySystem = !!url;

  return existsInLibrarySystem ? { ...work, integratedWorkOrLibrarySystemUrl: url } : undefined;
};
