import {
  DocumentType,
  Expression,
  Language,
  Manifestation,
  Title,
  UniformTitleMusic,
  Work,
  formatIsbdTitle,
} from "@biblioteksentralen/cordata";
import { sortByMultiple } from "@biblioteksentralen/utils";
import { sift, unique } from "radash";
import { isValidCoverImage } from "./coverImage";
import { getDocumentTypeLabel } from "./documentTypes";
import {
  CordataLanguageCode,
  LanguagesSignature,
  createLanguageCodeOrder,
  getSortedLanguagesListIndex,
  handleLanguages,
  languageListsEqual,
  sortedCordataLanguageCodes,
} from "./languages";
import { havePartTitles, haveSamePartTitlesSctructure } from "./titles";
import { ExpressionSummary, SortableManifestation } from "../types";
import { WorkSearchResult } from "../searchApi/searchWorks";

export const getDocumentTypes = ({ expressions }: Pick<Work, "expressions">): DocumentType[] =>
  unique(
    (expressions ?? []).flatMap(({ manifestations = [], aggregateManifestations = [] }) =>
      sift([...manifestations, ...aggregateManifestations].flatMap(({ documentType }) => documentType))
    ),
    getDocumentTypeLabel
  );

const defaultFormatOrder = ["Bok", "E-bok", "Lydbok", "E-lydbok"];

export const getFormatOrderIndex = (
  format: DocumentType["format"] | undefined,
  preferredFormatOrder = defaultFormatOrder
): number =>
  format && defaultFormatOrder.includes(format) ? preferredFormatOrder.indexOf(format) : preferredFormatOrder.length;

type Representation = {
  representativeLanguages?: LanguagesSignature;
  representativeFormat?: DocumentType["format"];
};

export type WorkToBeRepresented = WorkSearchResult & Representation;

export const getSortableManifestation = (
  manifestation: Manifestation,
  expression: ExpressionSummary<Expression>,
  preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
): SortableManifestation => ({
  ...manifestation,
  collections: manifestation.collections,
  expression: {
    ...expression,
    languages: handleLanguages(preferredLanguageCodeOrder)(expression.languages),
    collections: expression.collections,
  },
});

export const findRepresentativeManifestation = (work: WorkSearchResult) => {
  const representativeExpression = work.expressions?.find(({ manifestations, aggregateManifestations }) => {
    const manifestation = manifestations?.find(({ id }) => id === work.representativeManifestationId);
    if (manifestation) return manifestation;
    const aggregateManifestation = aggregateManifestations?.find(({ id }) => id === work.representativeManifestationId);
    return aggregateManifestation;
  });

  const representativeManifestation = representativeExpression?.manifestations?.find(
    ({ id }) => id === work.representativeManifestationId
  );
  if (representativeManifestation) return representativeManifestation;

  const representativeAggregateManifestation = representativeExpression?.aggregateManifestations?.find(
    ({ id }) => id === work.representativeManifestationId
  );
  return representativeAggregateManifestation;
};

export const markAsAggregate = (manifestation: Manifestation, isAggregateManifestation: boolean = true) => ({
  ...manifestation,
  isAggregateManifestation,
});

const getSortableManifestationsFromWork = (
  { expressions }: Work,
  preferredLanguageCodeOrder?: readonly CordataLanguageCode[]
): SortableManifestation[] =>
  unique(
    (expressions ?? []).flatMap(({ manifestations = [], aggregateManifestations = [], ...expression }) =>
      [
        ...manifestations,
        ...aggregateManifestations.map((aggregateManifestation) => markAsAggregate(aggregateManifestation, true)),
      ].map((manifestation) => getSortableManifestation(manifestation, expression, preferredLanguageCodeOrder))
    ),
    ({ id }) => id
  );

export type ManifestationSorter = (items: SortableManifestation[]) => SortableManifestation[];

export const sortManifestationsByRelevance =
  (
    workTitle: Title | UniformTitleMusic,
    preferredLanguageCodeOrder: readonly CordataLanguageCode[] = sortedCordataLanguageCodes,
    preferredFormatOrder: DocumentType["format"][],
    representativeManifestationId?: string
  ): ManifestationSorter =>
  (items) =>
    sortByMultiple(
      items,
      ({ id }) => (id === representativeManifestationId ? 0 : 1),
      ({ expression }) => getSortedLanguagesListIndex(expression.languages, preferredLanguageCodeOrder),
      ({ documentType }) => getFormatOrderIndex(documentType?.format, preferredFormatOrder),
      ({ isAggregateManifestation }) => (isAggregateManifestation ? 2 : 1),
      ({ title }) =>
        workTitle.titleType! === "uniform_title_music" && haveSamePartTitlesSctructure(workTitle as Title, title)
          ? 1
          : 2,
      ({ publicationYear }) => (isNaN(Number(publicationYear)) ? 0 : -Number(publicationYear)),
      ({ coverImage }) => (isValidCoverImage(coverImage) ? 1 : 2)
    );

/**
 * Preferred in order: language then published date.
 * Choose an alternative cover image if necessary, in the same language
 *
 * TODO: Think about parts, series...
 * TODO: Unit test
 */
export const getRepresentativeManifestation = ({
  representativeLanguages,
  representativeFormat,
  representativeManifestationId,
  ...work
}: WorkToBeRepresented): SortableManifestation | undefined => {
  const languageCodeOrder = createLanguageCodeOrder(representativeLanguages);
  const formatOrder = representativeFormat ? unique([representativeFormat, ...defaultFormatOrder]) : defaultFormatOrder;

  const sortManifestations = sortManifestationsByRelevance(
    work.title,
    languageCodeOrder,
    formatOrder,
    representativeManifestationId
  );

  const sortedManifestations = sortManifestations(getSortableManifestationsFromWork(work, languageCodeOrder));
  const representativeManifestation = sortedManifestations?.[0];

  if (!representativeManifestation) return undefined;

  const representativeLanguage = representativeManifestation.expression.languages[0]?.code;

  const mostRelevantCoverImage =
    sortedManifestations
      .filter(({ expression }) => !!expression.languages[0] && expression.languages[0].code === representativeLanguage)
      // TODO: Also force same document type?
      .find(({ coverImage }) => isValidCoverImage(coverImage))?.coverImage ??
    //as music work generally dont have languages, fallback to finding image from sortedManifestations without filtering it
    sortedManifestations.find(({ coverImage }) => isValidCoverImage(coverImage))?.coverImage;

  return { ...representativeManifestation, coverImage: mostRelevantCoverImage };
};

export const getFormattedRepresentativeManifestationTitle = (
  work: Work,
  representativeManifestation: SortableManifestation | undefined,
  includePartNumber: boolean = true
) => {
  const title = representativeManifestation?.isAggregateManifestation
    ? representativeManifestation?.expression.title ?? work.title
    : representativeManifestation?.title;

  const titleWithoutPartNumber: Title | UniformTitleMusic | undefined =
    title && !isUniformTitleMusic(title) ? { ...title, partNumber: undefined } : title;

  const titleToUse = (includePartNumber ? title : titleWithoutPartNumber) ?? work.title;

  return title && formatIsbdTitle({ ...work, title: titleToUse }, { skipSubtitle: havePartTitles(work.title) });
};

const isUniformTitleMusic = (title: Title | UniformTitleMusic | undefined): title is UniformTitleMusic =>
  title?.titleType === "uniform_title_music";

export const getRelevantManifestations = (
  work: Work,
  languagesList: Language[] | undefined,
  documentType: DocumentType | undefined
) => {
  if (!languagesList || !documentType) return [];

  const sortableManifestations = getSortableManifestationsFromWork(work);

  return sortableManifestations.filter(
    (manifestation) =>
      languageListsEqual(manifestation.expression.languages, languagesList) &&
      manifestation.documentType?.code === documentType.code
  );
};

const getDefaultManifestationOrder = (work: Work) =>
  sortManifestationsByRelevance(work.title, [], [])(getSortableManifestationsFromWork(work));

export const getDefaultWorkImage = (work: Work) =>
  getDefaultManifestationOrder(work).find((manifestation) => manifestation.coverImage)?.coverImage;

export const getDefaultManifestation = (work: Work) => getDefaultManifestationOrder(work)?.[0];
